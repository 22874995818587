<template>
  <div>
    <breadcrumb current-page="Download History" />
    <div class="download-history">
      <div class="card-base">
        <div class="card-head-bar">
          <div class="head-title">
            <h1>Download History</h1>
            <sub>View your previous reports, run the searches again, or download the reports below.</sub>
            <sub>
              <el-popover
                v-model="showPopover"
                trigger="click"
                placement="bottom-end"
                popper-class="light remaining-dloads-history">
                <a
                  class="btn btn-text secondary"
                  @click="showPopover = false"><i class="removed-icon"/>
                </a>
                <div class="popover-content">
                  <div class="pop-over-default">
                    <credit-widget
                      :values="{
                        percentage: downloadPercentage,
                        credits: downloadCredits
                      }"
                      label="Downloads"
                      credits-label="records"/>
                  </div>
                </div>
                <a
                  slot="reference"
                  class="btn-default btn-text inline-normal">View download credits.</a>
              </el-popover>
            </sub>
          </div>
          <div class="head-toolbar"/>
        </div>
        <date-separator
          :is-fetching="$actions.pending('downloads/fetchDownloadHistory')"
          :items="allDownloads"
          empty-message="No download history available."
          date="requested_on">
          <el-collapse
            slot="items"
            slot-scope="data"
            v-model="activeDownloads"
            class="plain">
            <download-history-collapse
              v-for="item in data.items"
              :key="item.id"
              :item="item"
              :search-credits="searchCredits"
              :uncollapsed="activeDownloads"
              @cancel-download="cancelDownload" />
          </el-collapse>
        </date-separator>
        <div
          v-loading="$actions.pending('downloads/fetchMoreHistory')"
          v-if="allDownloads.length"
          class="item-loader">
          <div
            v-if="lastRow"
            class="end-of-list">You have reached the end of the list.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Breadcrumb from '~/components/common/Breadcrumb'
import DateSeparator from '~/components/common/DateSeparator'
import DownloadHistoryCollapse from '~/components/collapse-items/DownloadHistoryCollapse'
import hideSidebarMixin from '~/mixins/hideSidebar'
import CreditWidget from '~/components/common/CreditWidget'

export default {
  name: 'DownloadHistory',
  head() {
    return {
      title:
        'Download History - ' + ISNG_TITLE /*eslint-disable-line no-undef */
    }
  },
  components: {
    Breadcrumb,
    DateSeparator,
    DownloadHistoryCollapse,
    CreditWidget
  },
  mixins: [hideSidebarMixin],
  data() {
    return {
      activeDownloads: [],
      showPopover: false
    }
  },
  computed: {
    ...mapGetters('downloads', ['allDownloads']),
    ...mapGetters('userCredits', [
      'searchCredits',
      'downloadPercentage',
      'downloadCredits'
    ]),
    ...mapState(['loadedThirdPartyScripts']),
    ...mapState('downloads', ['minDate', 'maxDate', 'dateRange', 'lastRow']),
    dateRangePresets() {
      return [
        ...this.$date.getCommonDateRanges(this.maxDate),
        { label: 'ALL', text: 'ALL', from: this.minDate, to: this.maxDate }
      ]
    }
  },
  mounted() {
    if (!this.loadedThirdPartyScripts) {
      this.loadThirdPartyScripts()
    }

    document.addEventListener('scroll', this.scroll)
  },
  destroyed() {
    document.removeEventListener('scroll', this.scroll)
  },
  methods: {
    ...mapActions(['loadThirdPartyScripts']),
    ...mapActions('downloads', [
      'setDateRange',
      'cancelDownload',
      'fetchMoreHistory'
    ]),
    changeDateRange(dateRange) {
      this.setDateRange(dateRange)
    },
    async scroll() {
      if (
        (window.innerHeight + window.scrollY || window.pageYOffset) >=
          document.body.offsetHeight &&
        !this.$actions.pending('downloads/fetchDownloadHistory') &&
        !this.$actions.pending('downloads/fetchMoreHistory') &&
        !this.lastRow
      ) {
        await this.fetchMoreHistory()
      }
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/breadcrumbs.scss"/>
<style lang="scss" src="~/assets/scss/sections/download/download-history.scss" />
<style lang="scss" src="~/assets/scss/components/data-viewer/data-collapse-viewer.scss" />
