<template>
  <div class="row-item">
    <el-collapse-item :name="item.export_id">
      <div
        slot="title"
        :class="{ active: item.export_status !== 'completed' }"
        class="single-column-item">
        <i :class="`icon ${item.file_format}-icon`" />
        <div class="flex-item file-name ellipses">
          <strong>{{ item.file_name }}.{{ item.file_format }}</strong>
          <sub>Filename</sub>
        </div>
        <div class="flex-item status">
          <div
            v-if="item.export_status !== 'queued'"
            class="progress">
            <div
              :aria-valuenow="item.progress"
              :style="`width: ${item.progress}%`"
              class="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"/>
          </div>
          <div class="removed-icon-div">
            <i
              v-if="item.export_status !== 'completed'"
              class="removed-icon"
              @click.stop="$emit('cancel-download', item.export_id)"/>
          </div>
          <sub v-if="item.export_status === 'queued'">Queued</sub>
          <sub v-else>{{ item.progress }}% complete</sub>
        </div>
        <div
          v-if="item.view"
          class="flex-item view-type">
          {{ item.view }}
          <sub>View</sub>
        </div>
        <div
          v-else
          class="flex-item view-type">
          {{ item.companyName }}
          <sub>Company Profile</sub>
        </div>
        <div class="flex-item num-records">
          {{ $numeral(item.number_of_rows_generated).format('0,0') }} records
          <sub>Number of Records</sub>
        </div>
        <div class="flex-item file-size">
          {{ fileSize }}
          <sub>File Size</sub>
        </div>
        <div class="flex-item file-size">
          {{ downloadOrigin }}
          <sub>Download Origin</sub>
        </div>
        <div
          v-if="item.export_status === 'completed' && !isOpened"
          class="item-buttons">
          <i
            class="eye-icon"
            @click.stop="$bus.$emit('openPreview', item)" />
          <i
            class="download-icon-dark"
            @click.stop="generateDownloadLink({ id: item.export_id })" />
        </div>
      </div>
      <div>
        <collapse-criteria
          v-if="fromSearch"
          :criteria="item.criteria"/>
        <div class="criteria-buttons">
          <img
            v-if="item.country"
            :src="require(`~/assets/img/flag/${item.country}.svg`)"
            :alt="item.country"
            class="flag">
          <label
            v-if="item.country">{{ item.country | upperCase }} {{ item.shipment_type | startCase }} Dataset</label>
          <div
            v-if="item.export_status === 'completed'"
            class="option-buttons flex-container justify-right">
            <div
              v-if="item.view && Object.values(searchable).includes(downloadOrigin)" >
              <button
                :class="{ disabled: hasNoKeyword(item.criteria) }"
                class="btn btn-text secondary"
                @click="searchAgain">Try to search again</button>
            </div>
            <nuxt-link
              v-if="[downloadOrigins.IMPORTER, downloadOrigins.SUPPLIER].includes(downloadOrigin)"
              :to="`/company/${item.companyId}/overview`"
              class="btn btn-text">View Profile</nuxt-link>
            <button
              class="btn btn-text secondary"
              @click="$bus.$emit('openPreview', item)">Preview</button>
            <el-popover
              ref="popover"
              placement="bottom-end"
              popper-class="download-option-popover open">
              <div class="pop-over-default">
                <ul>
                  <li
                    @click="downloadAndClosePopover(false)">Download {{ item.file_format | upperCase }}</li>
                  <li @click="downloadAndClosePopover(true)">Download as ZIP</li>
                </ul>
              </div>
              <button
                slot="reference"
                class="btn btn-default btn-primary">Download</button>
            </el-popover>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import filesize from 'filesize'
import { upperCase, startCase } from 'lodash'
import CollapseCriteria from './CollapseCriteria'
import downloadExport from '~/mixins/downloadExport'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'DownloadHistoryCollapse',
  components: {
    CollapseCriteria
  },
  filters: {
    upperCase,
    startCase
  },
  mixins: [downloadExport, openUpgradeModal],
  props: {
    item: {
      type: Object,
      required: true
    },
    uncollapsed: {
      type: Array,
      required: true
    },
    searchCredits: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      downloadOrigins: {
        IMPORTER: 'IMPORTERS',
        SUPPLIER: 'SUPPLIERS',
        CONSIGNEE: 'CONSIGNEES',
        SHIPPER: 'SHIPPERS',
        SHIPMENT_SEARCH: 'SEARCH RESULTS',
        DEFAULT: 'NOT AVAILABLE'
      },
      searchable: {
        CONSIGNEE: 'CONSIGNEES',
        SHIPPER: 'SHIPPERS',
        SHIPMENT_SEARCH: 'SEARCH RESULTS'
      }
    }
  },
  computed: {
    ...mapState(['baseLink']),
    ...mapState('views', ['views', 'activeShipmentsView']),
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('search', ['hasSearch']),
    ...mapGetters('userSubscriptions', ['withinSubscriptionDateRange']),
    fileSize() {
      return filesize(this.item.file_size, { base: 10 })
    },
    isOpened() {
      return (
        this.uncollapsed.findIndex(
          download => download === this.item.export_id
        ) > -1
      )
    },
    fromSearch() {
      return ['consignee', 'shipper', 'shipment search'].includes(
        this.item.download_source.toLowerCase()
      )
    },
    downloadOrigin() {
      const source = this.item.download_source.toUpperCase().replace(' ', '_')

      return this.downloadOrigins[source] || this.downloadOrigins.DEFAULT
    },
    arrivalDateWithinSubscriptionDateRange() {
      const [from, to] = this.item.criteria.arrivalDates.split('-').map(v => +v)

      return this.withinSubscriptionDateRange({ from, to })
    },
    searchAgainFeature() {
      let notAllowedReason

      if (!this.searchCredits) {
        notAllowedReason = 'You have 0 search credits left.'
      }

      if (!this.arrivalDateWithinSubscriptionDateRange) {
        notAllowedReason =
          'Arrival date criteria is not within your current subscription.'
      }

      return {
        isAllowed: !notAllowedReason,
        notAllowedReason
      }
    }
  },
  methods: {
    ...mapMutations('views', [
      'setActiveShipmentsView',
      'setActiveAggregateView'
    ]),
    ...mapMutations('search', ['setComponentLoading']),
    ...mapActions('search', [
      'fetchShipmentsBySearchId',
      'fetchDownloadSearchId',
      'clearSearch'
    ]),
    ...mapActions('downloads', ['fetchDownloadHistory']),
    async searchAgain() {
      const keywords = this.item.criteria.keywords

      if (Array.isArray(keywords) && !keywords.length) {
        if (this.hasSearch) this.clearSearch()
        this.$router.push(this.baseLink)
      } else {
        let searchId = this.item.search_id

        this.setComponentLoading(true)

        /* for backward compatibility (to those that do not have search id) */
        if (!searchId) {
          searchId = await this.fetchDownloadSearchId({
            searchCriteria: this.item.criteria,
            exportId: this.item.export_id
          })
        }

        this.fetchShipmentsBySearchId({ searchId }).then(() => {
          this.$router.push(this.currentSearchURL)
          if (!this.item.search_id) this.fetchDownloadHistory(false)
        })
      }

      // if (this.item.view && this.item.view !== this.activeShipmentsView.name) {
      //   const view = this.views.find(v => v.name === this.item.view)
      //   if (view) {
      //     this.setActiveShipmentsView(view)
      //   }
      // }

      let view = this.downloadOrigin

      if (view === this.downloadOrigins.SHIPMENT_SEARCH) {
        view = 'shipments'
      } else {
        view = view.toLowerCase()
      }

      this.setActiveAggregateView(view)
    },
    downloadAndClosePopover(zip) {
      this.generateDownloadLink({ id: this.item.export_id, zip })
      this.$refs.popover.doClose()
    },
    hasNoKeyword(criteria) {
      if (
        !criteria.keywords ||
        (Array.isArray(criteria.keywords) && !criteria.keywords.length)
      ) {
        return true
      }

      return false
    }
  }
}
</script>
